@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
//@import '~html5-device-mockups/dist/device-mockups.css';
@import '~vue-js-modal/dist/styles.css';
@import '~vue-promise-btn/dist/vue-promise-btn.css';
@import '../../src/libraries/searchbar.js/src/styles/main.scss';
@import 'vue-multiselect.scss';
@import '~@riophae/vue-treeselect/dist/vue-treeselect.css';
//@import '~vueperslides/dist/vueperslides.css';
@import '~swiper/css/swiper.css';
@import '~@assets/sass/animate.css';

/* @import url("//hello.myfonts.net/count/2f0200"); */

@font-face {
	font-family: 'MistralStd';
	src: url('~@assets/fonts/2F0200_0_0.eot');
	src: url('~@assets/fonts/2F0200_0_0.eot?#iefix') format('embedded-opentype'),
		 url('~@assets/fonts/2F0200_0_0.woff2') format('woff2'),
		 url('~@assets/fonts/2F0200_0_0.woff') format('woff'),
		 url('~@assets/fonts/2F0200_0_0.ttf') format('truetype');
	font-display: block;
}

@font-face {
	font-family: 'Mulish';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		 url('~@assets/fonts/mulish-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/mulish-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Catamaran';
	font-style: normal;
	font-weight: 200;
	src: local(''),
		 url('~@assets/fonts/catamaran-v17-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/catamaran-v17-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Catamaran';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		 url('~@assets/fonts/catamaran-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/catamaran-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Catamaran';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		 url('~@assets/fonts/catamaran-v17-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/catamaran-v17-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Catamaran';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		 url('~@assets/fonts/catamaran-v17-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/catamaran-v17-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		 url('~@assets/fonts/lato-v23-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/lato-v23-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Flaticon';
	src: url('~@assets/fonts/Flaticon.eot');
	src: url('~@assets/fonts/Flaticon.eot?#iefix') format('embedded-opentype'),
		 url('~@assets/fonts/Flaticon.woff') format('woff'),
		 url('~@assets/fonts/Flaticon.ttf') format('truetype'),
		 url('~@assets/fonts/Flaticon.svg#Flaticon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: url('~@assets/fonts/poppins-v20-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/poppins-v20-latin-300.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('~@assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/poppins-v20-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 400;
	src: url('~@assets/fonts/poppins-v20-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/poppins-v20-latin-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: url('~@assets/fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/poppins-v20-latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: url('~@assets/fonts/poppins-v20-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/poppins-v20-latin-600.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: url('~@assets/fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/poppins-v20-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 800;
	src: url('~@assets/fonts/poppins-v20-latin-800.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/poppins-v20-latin-800.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 900;
	src: url('~@assets/fonts/poppins-v20-latin-900.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
		 url('~@assets/fonts/poppins-v20-latin-900.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'Flaticon';
		src: url('~@assets/fonts/Flaticon.svg#Flaticon') format('svg');
	}
}