@use "sass:math";

@function even-px($value) {
  @if type-of($value) == 'number' {
    @if (unitless($value)) {
      $value: $value * 1px;
    } @else if unit($value) == 'em' {
      $value: (math.div($value, 1em) * 16px);
    } @else if unit($value) == 'pts' {
      $value: $value * 1.3333 * 1px;
    } @else if unit($value) == '%' {
      $value: math.div($value * 16, 100%) * 1px;
    }
    $value: round($value);
    @if ($value % 2 != 0px) {
      $value: $value + 1;
    }
    @return $value;
  }
}

@mixin searchbox(
  $font-size: 90%,
  $input-width: 350px,
  $input-height: $font-size * 2.4,
  $border-width: 1px,
  $text-color: #555,
  $border-radius: $input-height * 0.5,
  $input-border-color: #ccc,
  $input-focus-border-color: #1ec9ea,
  $input-background: #f8f8f8,
  $input-focus-background: #fff,
  $placeholder-color: #aaa,
  $icon: 'sbx-icon-search-1',
  $icon-size: math.div($input-height, 1.6),
  $icon-position: left,
  $icon-color: #888,
  $icon-background: $input-focus-border-color,
  $icon-background-opacity: 0.1,
  $icon-clear: 'sbx-icon-clear-1',
  $icon-clear-size: math.div($font-size, 1.1),
  $dark-mode: false
) {
  .searchbox {
    display: inline-block;
    position: relative;
    width: $input-width;
    height: even-px($input-height);
    white-space: nowrap;
    box-sizing: border-box;
    visibility: visible;

    .meilisearch-autocomplete {
      display: block;
      width: 100%;
      height: 100%;
    }

    &__wrapper {
      width: 100%;
      height: 100%;
      z-index: 999;
      position: relative;
    }

    input {
      color: $text-color;
      display: inline-block;
      box-sizing: border-box;
      transition: box-shadow 0.4s ease, background 0.4s ease;
      border: 0;
      border-radius: even-px($border-radius);
      box-shadow: inset 0 0 0 $border-width $input-border-color;
      background: $input-background;
      padding: 0;
      padding-right: if(
          $icon-position == 'right',
          even-px($input-height) + even-px($icon-clear-size) + 8px,
          even-px($input-height * 0.8)
        ) + if($icon-background-opacity == 0, 0, even-px($font-size));
      padding-left: if(
        $icon-position == 'right',
        even-px($font-size * 0.5) + even-px($border-radius * 0.5),
        even-px($input-height) +
          if($icon-background-opacity == 0, 0, even-px($font-size * 1.2))
      );
      width: 100%;
      height: 100%;
      vertical-align: middle;
      white-space: normal;
      font-size: $font-size;
      appearance: none;

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }

      &:hover {
        box-shadow: inset 0 0 0 $border-width darken($input-border-color, 10%);
        @if $dark-mode {
          box-shadow: inset
            0
            0
            0
            $border-width
            lighten($input-border-color, 5%);
        }
      }

      &:focus,
      &:active {
        outline: 0;
        box-shadow: inset 0 0 0 $border-width $input-focus-border-color;
        background: $input-focus-background;
      }

      &::placeholder {
        color: $placeholder-color;
      }
    }

    &__submit {
      position: absolute;
      top: 0;
      margin: 0;
      border: 0;
      border-radius: if(
        $icon-position == 'right',
        0 $border-radius $border-radius 0,
        $border-radius 0 0 $border-radius
      );
      background-color: rgba($icon-background, $icon-background-opacity);
      padding: 0;
      width: even-px($input-height) +
        if($icon-background-opacity == 0, 0, even-px($font-size * 0.5));
      height: 100%;
      vertical-align: middle;
      text-align: center;
      font-size: inherit;
      user-select: none;
      @if $icon-position == 'right' {
        right: 0;
        left: inherit;
      } @else {
        right: inherit;
        left: 0;
      }

      // Helper for vertical alignment of the icon
      &::before {
        display: inline-block;
        margin-right: -4px;
        height: 100%;
        vertical-align: middle;
        content: '';
      }

      &:hover,
      &:active {
        cursor: pointer;
      }

      &:focus {
        outline: 0;
      }

      svg {
        width: even-px($icon-size);
        height: even-px($icon-size);
        vertical-align: middle;
        fill: $icon-color;
      }
    }

    &__reset {
      display: block;
      position: absolute;
      top: (even-px($input-height) - even-px($icon-clear-size)) * 0.5 - 4px;
      right: if(
        $icon-position == 'right',
        even-px($input-height) +
          if($icon-background-opacity == 0, 0, even-px($font-size)),
        (even-px($input-height) - even-px($icon-clear-size)) * 0.5 - 4px
      );
      margin: 0;
      border: 0;
      background: none;
      cursor: pointer;
      padding: 0;
      font-size: inherit;
      user-select: none;
      fill: rgba(#000, 0.5);

      &.hide {
        display: none;
      }

      &:focus {
        outline: 0;
      }

      svg {
        display: block;
        margin: 4px;
        width: even-px($icon-clear-size);
        height: even-px($icon-clear-size);
      }
    }

    &__input:valid ~ &__reset {
      display: block;
      animation-name: sbx-reset-in;
      animation-duration: 0.15s;
    }

    @at-root {
      @keyframes sbx-reset-in {
        0% {
          transform: translate3d(-20%, 0, 0);
          opacity: 0;
        }

        100% {
          transform: none;
          opacity: 1;
        }
      }
    }
  }
}
