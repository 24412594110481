// SEARCHBOX
$searchbox-config: (
  input-width: 200px,
  input-height: 32px,
  border-width: 1px,
  border-radius: 16px,
  text-color: #555,
  input-border-color: #ccc,
  input-focus-border-color: #aaa,
  input-background: #fff,
  input-focus-background: #fff,
  font-size: 12px,
  placeholder-color: #aaa,
  icon-size: 14px,
  icon-position: left,
  icon-color: #6d7e96,
  icon-background: #458ee1,
  icon-background-opacity: 0,
  icon-clear-size: 8px,
  dark-mode: false,
) !default;

$searchbox-config-dark: (
  input-height: 32px,
  text-color: #eaeaea,
  input-border-color: #686d71,
  input-focus-border-color: #919598,
  input-background: #444d52,
  input-focus-background: #444d52,
  placeholder-color: #bbbbbb,
  icon-size: 14px,
  icon-color: #6d7e96,
  icon-background: #458ee1,
  icon-background-opacity: 0,
  dark-mode: true,
) !default;

// DROPDOWN
$dropdown-config: (
  main-color: #458ee1,
  layout-type: normal,
  layout-width: normal,
  layout-alignment: align,
  background-color: #fff,
  border-radius: 4,
  border-width: 1,
  border-color: #d9d9d9,
  box-shadow: light,
  branding-position: bottom,
  spacing: normal,
  include-desc: yes,
  background-category-header: #fff,
  font-size: normal,
  header-color: #33363d,
  title-color: #02060c,
  subtitle-color: #a4a7ae,
  text-color: #63676d,
  highlight-color: #3881ff,
  highlight-opacity: 0.1,
  highlight-type: underline,
  suggestion-background-color: #f8f8f8,
  hover-color-opacity: 0.05,
  dark-mode: false,
) !default;

// Dropdown dark mode
$dropdown-config-dark: (
  background-color: #2c363e,
  title-color: #eaeaea,
  text-color: #eaeaea,
  subtitle-color: #bbbbbb,
  header-color: #7db0ea,
  main-color: #458ee1,
  background-category-header: #eaeaea,
  border-color: #5b6369,
  highlight-color: #3881ff,
  suggestion-background-color: #6b7278,
  hover-color-opacity: 0.5,
  dark-mode: true,
) !default;

$builder-height: 260px;
