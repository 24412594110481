@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

$tag-margin: 3px;

.multiselect {
  color: $input-color;

  .multiselect__tags {
    border-color: $input-border-color;
    border-radius: $input-border-radius;
    font-size: $input-font-size;
    padding: $input-padding-y $input-padding-x;
    min-height: unset;

    .multiselect__tag {
      background-color: #0a5384;
      margin-top: $tag-margin;
      margin-bottom: -$tag-margin;

      &-icon {
        &:after {
          color: $light;
        }

        &:hover {
          background: darken($primary, 5%);
        }
      }
    }

    .multiselect__placeholder {
      margin-bottom: 0;
      color: $input-placeholder-color;
      padding: 0;
    }

    .multiselect__input, .multiselect__single {
      line-height: $input-line-height;
      padding: 0;
      margin-bottom: 0;
    }
  }

  .multiselect__select {
    background: none !important;
  }

  .multiselect__option--highlight {
    background-color: #0a5384;
  }

  &.is-invalid {
    .multiselect__tags {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem) !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .multiselect__select {
      display: none;
    }
  }

  &.multiselect--active.is-invalid {
    .multiselect__tags {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
    }
  }
}

.multiselect--disabled {
  background: none !important;
}