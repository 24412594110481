// Variables

$theme-primary:    #f3fbfd;
$theme-secondary:  #0d81aa;
$theme-tertiary:   #28b0c6;

$gray-base:        #000;
$gray-darker:      lighten($gray-base, 13.5%); // #222
$gray-dark:        lighten($gray-base, 20%);   // #333
$gray:             lighten($gray-base, 33.5%); // #555
$gray-light:       lighten($gray-base, 46.7%); // #777
$gray-lighter:     lighten($gray-base, 93.5%); // #eee

$brand-twitter:		#1da1f2;
$brand-facebook:	#3b5998;
$brand-google-plus:	#dd4b39;

$main-font: 'Roboto',sans-serif;
$primary-color: #57e2b2;
$light-gray: #f8f8f8;
$main-font-color: #808080;
$main-font-weight: 300;

// Mixins

@mixin transition-all() {
	-webkit-transition: all .35s;
	-moz-transition: all .35s;
	transition: all .35s;
}

@mixin background-cover() {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

@mixin button-variant($color, $background, $border) {
	color: $color;
	border-color: $border;
	background-color: $background;
	&:focus,
	&.focus {
		color: $color;
		border-color: darken($border, 25%);
		background-color: darken($background, 10%);
	}
	&:hover {
		color: $color;
		border-color: darken($border, 12%);
		background-color: darken($background, 10%);
	}
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		color: $color;
		border-color: darken($border, 12%);
		background-color: darken($background, 10%);
		&:hover,
		&:focus,
		&.focus {
			color: $color;
			border-color: darken($border, 25%);
			background-color: darken($background, 17%);
		}
	}
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus,
		&.focus {
			border-color: $border;
			background-color: $background;
		}
	}
	.badge {
		color: $background;
		background-color: $color;
	}
}

@mixin heading-font {
	font-family: Catamaran, Arial, sans-serif;
	font-weight: 200;
	letter-spacing: 1px;
}

@mixin body-font {
	font-family: Mulish, 'Trebuchet MS', Arial, sans-serif;
}

@mixin alt-font {
	font-family: Lato, Arial, sans-serif;
	letter-spacing: 2px;
	text-transform: uppercase;
}

// Global styling for this template
html,
body {
	width: 100%;
	height: 100%;
}

body {
	@include body-font;
}

a {
	color: $theme-primary;
	@include transition-all;
	&:hover,
	&:focus {
		color: darken($theme-primary, 10%);
	}
}

hr {
	max-width: 100px;
	margin: 25px auto 0;
	border-width: 1px;
	border-color: fade-out($gray-darker, .9);
}

hr.light {
	border-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include heading-font;
}

p {
	font-size: 18px;
	line-height: 1.5;
	margin-bottom: 20px;
}

section {
	padding: 30px 0 50px;
	
	h1,
	h2 {
		font-size: 50px;
	}
}

// Bootstrap overrides for this template
.bg-primary {
	background: $theme-primary;
	background: linear-gradient($theme-primary, darken($theme-primary, 5%));
}

.text-primary {
	color: $theme-primary;
}

.no-gutter > [class*='col-'] {
	padding-right: 0;
	padding-left: 0;
}

small.form-notice {
	color: #999;
	font-size: 0.8em;
	
	a {
		color: inherit;
		text-decoration: underline;
	}
}

.btn-outline {
	color: #fff;
	background: transparent;
	border: 1px solid;
	border-color: #fff;
	&:hover,
	&:focus,
	&:active,
	&.active {
		color: $gray-dark;
		border-color: $theme-primary;
		background-color: $theme-primary;
	}
}
.btn-primary-outline {
	color: #2fbbcc;
	border: 1px solid;
	border-color: #2fbbcc;
	&:hover,
	&:focus,
	&:active,
	&.active {
		color: #fff;
		border-color: #2fbbcc;
		background-color: #2fbbcc;
	}
}

.btn {
	border-radius: 300px;
	@include alt-font;
}

.btn-primary {
	background-color: #2fbbcc;
	border-color: #2fbbcc;
	&:hover,
	&:focus,
	&:active,
	&.active {
		color: $gray-dark;
		border-color: #fff;
		background-color: #fff;
		border-color: $gray-dark;
	}
}

.btn-secondary {
	color: #2fbbcc;
	background-color: #fff;
	border-color: #fff;
	&:hover,
	&:focus,
	&:active,
	&.active {
		color: #fff;
		border-color: #2fbbcc;
		background-color: #2fbbcc;
		border-color: #2fbbcc;
	}
}

.btn-xl {
	font-size: 16px;
	padding: 15px 45px;
}

.breadcrumb {
	background-color: transparent;
	margin-bottom: 0;
	padding-bottom: 0;
	
	.breadcrumb-item > a {
		color: #2fbbcc;
	}
}

// WP articles import

.alignleft,
img.alignleft {
	display: inline;
	float: left;
	margin-right: 24px;
	margin-top: 4px;
}
.alignright,
img.alignright {
	display: inline;
	float: right;
	margin-left: 24px;
	margin-top: 4px;
}
.aligncenter,
img.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
img.alignleft,
img.alignright,
img.aligncenter {
	margin-bottom: 12px;
}

.article-modal figure {
	background: #f1f1f1;
	line-height: 18px;
	margin-bottom: 20px;
	padding: .25rem;
	text-align: center;
	
	> figcaption {
		color: #888;
		font-size: 12px;
		margin: 5px;
	}
}

.article-modal .associated-product,
.article-modal .recommended-product {
	transition: transform .5s;
	
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity 2s cubic-bezier(.165, .84, .44, 1);
		box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
		content: '';
		opacity: 0;
		z-index: -1;
	}
	&:hover,
	&:focus {
		transform: scale3d(1.006, 1.006, 1);
	}
	&:hover::after,
	&:focus::after {
		opacity: 1;
	}
	
	a {
		text-decoration: none;
		
		h6 {
			font-size: small;
		}
	}
}

// Landing Page

section.news {
	position: relative;
	background-color: #fefefe;
	padding-top: 50px;
	
	.section-heading {
		color: #0a5384;
	}
	
	p {
		color: $gray-light;
	}
	
	.col-12 > .card {
		box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
		margin-bottom: 30px;
		border: none;
		
		&:hover {
			cursor: pointer;
			
			.card-footer {
				background-color: #e3a220;
				color: #fff;
			}
		}
		
		.card-header {
			color: $gray-light;
			background-color: #fff;
			border-bottom: none;
			font-size: 14px;
			
			span.badge-pill {
				color: #fff;
				font-size: inherit;
				margin-left: 3px;
				margin-bottom: 3px;
			}
		}
		
		.card-title {
			color: #0a5384;
			font-weight: 600;
		}
		
		.card-body {
			.card-text {
				font-size: smaller;
			}
			
			img {
				max-height: 80px;
				margin-bottom: .75rem;
			}
		}
		
		.card-footer {
			border-top: none;
			font-weight: 600;
			color: $gray;
			padding: 0;
			
			a,
			span {
				text-decoration: none;
				padding: .75rem 1.25rem;
				display: block;
				color: inherit;
			}
		}
	}
}

section.register {
	position: relative;
	
	box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.1);
	padding-top: 40px;
	padding-bottom: 40px;
	
	width: 100%;
	
	background: url('~@assets/img/SignUp-BG-Image.jpg'), $theme-secondary;
	background: url('~@assets/img/SignUp-BG-Image.jpg'), linear-gradient(to left, $theme-secondary 15%, $theme-tertiary 60%);
	background-size: 125%;
	background-repeat: no-repeat;
	
	&:after {
		display: none;
		content: url('~@assets/img/Business-Hover.jpg') url('~@assets/img/Doctors-Hover.jpg');
	}
	
	.header-row {
		color: #fff;
		margin-bottom: 30px;
	}
	
	.card {
		max-width: 500px;
		margin: 0 auto;
		
		.card-header {
			border-bottom: 0px;
			margin-bottom: -20px;
			padding-top: 25px;
			color: #fff;
			position: relative;
			overflow-x: hidden;
			
			h3 {
				position: absolute;
				top: 20%;
				left: 50%;
				width: 100%;
				transform: translate(-50%, -50%);
			}
			
			&.card-left {
				background: url('~@assets/img/Business-Unselected-New.jpg') transparent no-repeat;
				background-size: 100%;
				background-position: 0 -30px;
				
				&:hover {
					background-image: url('~@assets/img/Business-Hover.jpg');
					background-position: 20% -30px;
				}
			}
			&.card-right {
				background: url('~@assets/img/Doctors-Unselected-New.jpg') transparent no-repeat;
				background-size: 100%;
				background-position: 0 -30px;
				
				&:hover {
					background-image: url('~@assets/img/Doctors-Hover.jpg');
					background-position: 20% -30px;
				}
			}
		}
		
		.card-body {
			padding-bottom: 0;
			
			form {
				text-align: left;
				padding-bottom: 15px;
			}
			
			form#corporate-registration-form h5 {
				margin-bottom: 0;
			}
			
			.card-text {
				color: #777;
				text-align: left;
			}
		}
	}
	
	@media (min-width: 992px) {
		.card {
			.card-header {
				padding-top: 0;
			}
		}
	}
}

header.surveys-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: url('~@assets/img/Header-Atlases.jpg'), $theme-secondary;
	background: url('~@assets/img/Header-Atlases.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: center;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
		background-position: 0 60%;
	}
}

section.surveys {
	.section-heading {
		color: #0a5384;
		margin-bottom: 0;
	}
	
	.header-row p {
		color: $gray-light;
	}
	
	h3 {
		color: #0a5384;
	}
	
	.card {
		p,
		li {
			font-size: 1rem;
		}
		
		a:not(.btn) {
			color: #2fbbcc;
		}
		
		.media-body h5 {
			color: #0a5384;
			font-weight: bold;
			font-size: 1.1rem;
		}
	}
}
